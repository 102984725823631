import { AuthService } from './../../services/auth.service';
import { ITransferRequest } from '../../../_models/transfer-sections/ITransferRequest';
import { ITransferRequestLog } from '../../../_models/transfer-sections/ITransferRequestLog';
import { IPreference } from '../../../_models/transfer-sections/IPreference';
import { PreferenceService } from '../../services/preference.service';
import { IServiceBook } from '../../../_models/profile-sections/IServiceBook';
import { ISingleLadyType } from '../../../_models/profile-sections/ISingleWomenInfo';
import { IProfile } from '../../../_models/account/IProfile';
import { IProfileLog } from '../../../_models/account/IProfileLog';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { IPreferenceLog } from 'src/app/_models/transfer-sections/IPreferenceLog';
import { IServiceBookLog } from 'src/app/_models/profile-sections/IServiceBookLog';
import { ActivatedRoute } from '@angular/router';
import { ISetting } from 'src/app/_models/cms/ISetting';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.css']
})
export class EmployeeProfileComponent implements OnInit {
  @Input() profile: IProfile;
  @Input() transferRequest: ITransferRequest;
  @Input() profileLog: IProfileLog;
  @Input() transferRequestLog: ITransferRequestLog;
  @Input() showProfile: boolean = true;
  @Input() moreProfile: boolean = true;
  @Input() showOther: boolean = true;
  @Input() showPreference: boolean = true;
  @Input() isEdit: boolean = true;
  preferences: IPreference[];
  preferencelogs: IPreferenceLog[];
  setting: ISetting;
  financialYearId;

  constructor(
    private preferenceService: PreferenceService,
    private authService: AuthService,
    private settingService: SettingService,
    private route: ActivatedRoute) {
      this.financialYearId = this.route.snapshot.paramMap.get('financialYearId');
     }

  ngOnChanges(): void {
  }

  async ngOnInit() {
    this.setting = await this.settingService.getSetting();
    this.settingService.getSetting().then(data => {
      this.setting = data;
    });
   
    if (this.setting?.financialYearId == this.financialYearId) {
      this.getPreferences();
    }
    else {
      if (this.transferRequestLog == undefined) {
        this.getPreferences();
      }
      else {
        this.getPreferenceLogs();
      }
    }
  }

  private getPreferences() {
    if (this.showPreference && this.transferRequest?.id) {
      this.preferenceService.getPreferencesByTransferRequest(this.transferRequest?.id)
        .subscribe((response: IPreference[]) => {
          if (response) {
            this.preferences = response;
          }
        });
    }
  }

  private getPreferenceLogs() {
    if (this.transferRequestLog.profileId1) {
      this.preferenceService.getPreferencesByTransferRequestLog(this.transferRequestLog.profileId1)
        .subscribe((response: IPreferenceLog[]) => {
          if (response) {
            this.preferencelogs = response;
            console.log("this.preferencelogs---", this.preferencelogs);
          }
        });
    }
  }

  getSingleLadyTypeLog(index: number) {
    return ISingleLadyType[index].replace("_", " ");
  }

  getServiceBookLog(v: IServiceBookLog[]) {
    return v ? v.find(x => x.isPresent == true) : null;
  }

  showNVSOutLog() {
    return this.profileLog.spouseInfoLogs?.length > 0;
  }

  showNVSInLog() {
    return this.profileLog.SpouseOutInfoLogs?.length > 0;
  }

  isSingleWomenLog() {
    return this.profileLog.singleWomenInfoLog?.length > 0;
  }

  isSpouseLog() {
    return this.profileLog.spouseInfoLogs?.length > 0 || this.profileLog?.SpouseOutInfoLogs?.length > 0;
  }

  get isAdminLog(): boolean {
    return this.authService.isInRole('admin');
  }



  getSingleLadyType(index: number) {
    return ISingleLadyType[index].replace("_", " ");
  }

  getServiceBook(v: IServiceBook[]) {
    return v ? v.find(x => x.isPresent == true) : null;
  }

  showNVSOut() {
    return this.profile.spouseInInfos.length > 0;
  }

  showNVSIn() {
    return this.profile.spouseOutInfos.length > 0;
  }

  isSingleWomen() {
    return this.profile.singleWomenInfos.length > 0;
  }

  isSpouse() {
    return this.profile.spouseInInfos.length > 0 || this.profile.spouseOutInfos.length > 0;
  }

  get isAdmin(): boolean {
    return this.authService.isInRole('admin');
  }
}