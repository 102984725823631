import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ProfileService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/profiles', httpClient, toastrService);
  }

  logged() {
    return this.httpClient.get(this.baseUrl + 'api/profiles/logged')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getSpouseByEmail(email: string) {
    return this.httpClient.get(this.baseUrl + 'api/profiles/get-spouse/' + email)
      .pipe(catchError(this.handleError.bind(this)))
  } 

  getSpouseByShalaId(shalaId: string) {
    return this.httpClient.get(this.baseUrl + 'api/profiles/get-spouse-shalaid/' + shalaId)
      .pipe(catchError(this.handleError.bind(this))) 
  }

  updateStatusPrincipal(resource) {
    return this.httpClient.post(this.baseUrl + 'api/profiles/update-status', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  updateStatusRegion(resource) {
    return this.httpClient.post(this.baseUrl + 'api/profiles/update-status-region', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }
  getprofileLog(filter) {
    const url = `${this.baseUrl}api/profiles/profile-log?${this.toQueryString(filter)}`;
    
    // Make an HTTP GET request and handle errors properly
    return this.httpClient.get(url)
        .pipe(
            catchError(this.handleError.bind(this)) // Handle errors using the defined method
        );               
  }
}       